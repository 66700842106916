:root {
    --color-primary: #a1a9b3;
    --color-secondary: #4e4e4e;
    --color-bg-primary: #373b4f;
    --color-bg-secondary: #1c252e;
    --color-bg-light: #3E4156;
    --color-error: #ff777a;
    --color-success: #77ff7a;
    --color-white: #fff;
    --color-black: #000;
    --color-lightgrey: #f2f2f2;
    --color-grey: #9f9e9c;
    --color-darkgrey: #222222;
    --color-grey-d: #656463;
    --color-light-grey: #c7c7c7;
    --color-light-green: rgba(174, 204, 184, 0.6);
    --color-button: #fec101;
    --color-red: #e55e64;
    --color-red-l: #ff8f92;
    --color-red-d: #ae2b3a;
    --color-darkred: #a14d50;
    --color-blue: #4db7d9;
    --color-blue-l: #85e9ff;
    --color-blue-d: #0087a7;
    --color-darkblue: #6f73e6;
    --color-yellow: #ffbb00;
    --color-green: #34a853;
    --color-green-d: #2f974b;
    --color-error: #fe4042;
    --color-error-l: hsl(359, 99%, 75%);
    --color-group-border: hsl(213, 11%, 47%);
    --color-button-default: #e0e0e0;
    --color-button-default-d: #aeaeae;
    --color-theme-primary: hsl(153, 43%, 51%);
    --color-theme-primary-l: hsl(153, 43%, 65%);
    --color-theme-primary-vl: hsl(153, 43%, 95%);
    --color-theme-primary-d: hsl(171, 64%, 32%);
    --color-theme-warning: hsl(45, 98%, 51%);
    --color-theme-warning-d: hsl(45, 98%, 46%);
    --color-theme-error: hsl(15, 98%, 51%);
    --color-theme-error-d: hsl(15, 98%, 46%);

    /* spacing */
    --pad: 0.5em;

    /* font */
    --font-xxl: 4rem;
    --font-xl: 2rem;
    --font-l: 1.5rem;
    --font-m: 1.2rem;
    --font-r: 1rem;
    --font-s: 0.8rem;
}
