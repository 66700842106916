@import url('../style/vars.css');

.my-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 88px;
    min-height: 36px;
    background: var(--color-button-default);
    border: none;
    border-radius: 2px;
    padding: 8px 16px;
    cursor: pointer;
    outline: inherit;
}

.my-button-label {
    display: flex;
    align-items: center;
    color: black;
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;
    user-select: none;
    white-space: nowrap;
}

.my-button:hover,
.my-button:focus {
    background-color: var(--color-button-default-d);
}

/* colors */

.my-button.primary {
    background: var(--color-theme-primary);
}

.my-button.primary .my-button-label {
    color: white;
}

.my-button.primary:hover,
.my-button.primary:focus {
    background-color: var(--color-theme-primary-d);
}

.my-button.secondary {
    background: var(--color-lightgrey);
}

.my-button.secondary .my-button-label {
    color: black;
}

.my-button.secondary:hover,
.my-button.secondary:focus {
    background-color: var(--color-grey);
}

.my-button.secondary:hover .my-button-label {
    color: white;
}

.my-button.success {
    background-color: var(--color-green);
}

.my-button.success .my-button-label {
    color: white;
}

.my-button.danger {
    background: var(--color-theme-error);
}

.my-button.danger .my-button-label {
    color: white;
}

.my-button.danger:hover,
.my-button.danger:focus {
    background-color: var(--color-theme-error-d);
}

.my-button.disabled {
    color: var(--color-grey-d);
    background-color: var(--color-lightgrey);
    cursor: default;
}

.my-button:hover.disabled,
.my-button:focus.disabled {
    background-color: var(--color-lightgrey);
}

/* variants */

.transparent {
    background: none;
    box-shadow: none;
}

.transparent:hover,
.transparent:focus {
    background: rgba(0, 0, 0, 0.1);
}

/* sizes */

.my-button.small {
    min-width: 60px;
    min-height: 30px;
    padding: 7px 8px;
}

.my-button.xsmall {
    min-width: 40px;
    min-height: 20px;
    padding: 5px 6px;
}

.my-button.xsmall .my-button-label {
    font-size: 0.75rem;
}
