@import url('style/vars.css');

* {
    box-sizing: border-box;
}

html {
    overflow-y: hidden;
    max-height: 100vh;
}

html * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* Table Styles Start */
.table {
    border-radius: 5px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.table > div {
    min-height: 50px;
}

.table.dense > div {
    min-height: 35px;
}

.table.zebra > div:nth-child(even) {
    background: #F5F5F5;
}

.table-row {
    display: flex;
    align-items: center;
    border-bottom: 1px lightgray solid;
}

.table-row::after {
    content: "";
    clear: both;
    display: table;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}

[class*="col-"] {
    float: left;
    padding: 10px 20px;
}

.col-1 {
    width: 8.33%;
}

.col-2 {
    width: 16.66%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.33%;
}

.col-5 {
    width: 41.66%;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 58.33%;
}

.col-8 {
    width: 66.66%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 83.33%;
}

.col-11 {
    width: 91.66%;
}

.col-12 {
    width: 100%;
}

/* Table Styles End */

.header-container {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
    position: relative;
}

.logo-title-container {
    display: flex;
    justify-content: space-between;
}

h2 {
    font-weight: 1.2;
}

#main-page-container {
    position: relative;
    min-height: 100vh;
}

#content-wrap {
    padding-bottom: 1.2rem; /* Footer height */
}

#footer {
    /*position: absolute; */
    bottom: 1px;
    width: 100%;
    height: 1.2rem; /* Footer height */
    font-size: var(--font-s);
    border-top: 1px lightgray solid;
    z-index: 1000;
}

a {
    color: var(--color-green);
    text-decoration: none;
}

#header-row {
    border-bottom: 1px lightgray solid;
    z-index: 1000;
    position: relative;
}

.invalid {
    border-color: red;
}

.hover-themed:hover {
    color: var(--color-theme-primary-d) !important;
}

.table-header {
    font-weight: 600;
}
